import { render, staticRenderFns } from "./popup-material-default.vue?vue&type=template&id=6b301e66&scoped=true"
import script from "./popup-material-default.vue?vue&type=script&lang=js"
export * from "./popup-material-default.vue?vue&type=script&lang=js"
import style0 from "./popup-material-default.vue?vue&type=style&index=0&id=6b301e66&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b301e66",
  null
  
)

export default component.exports